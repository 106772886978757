// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/.pnpm/css-loader@6.11.0_webpack@5.96.1/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagelet-objective-overview-person[data-v-86719e4e] {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-image: url(//img-ys011.didistatic.com/static/ddo_web_static/do1_rpMhVX7G8mbqfeviQQFd);
  font-size: 12px;
  background-size: 100% 100%;
  position: relative;
}
.pagelet-objective-overview-person .leftImg[data-v-86719e4e] {
  position: absolute;
  bottom: 0;
  left: 0;
}
.pagelet-objective-overview-person .rightImg[data-v-86719e4e] {
  position: absolute;
  top: 50px;
  right: 0;
}
`, "",{"version":3,"sources":["webpack://./src/style.scoped.less"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,gGAAgG;EAChG,eAAe;EACf,0BAA0B;EAC1B,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,OAAO;AACT;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;AACV","sourcesContent":[".pagelet-objective-overview-person[data-v-86719e4e] {\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  background-image: url(//img-ys011.didistatic.com/static/ddo_web_static/do1_rpMhVX7G8mbqfeviQQFd);\n  font-size: 12px;\n  background-size: 100% 100%;\n  position: relative;\n}\n.pagelet-objective-overview-person .leftImg[data-v-86719e4e] {\n  position: absolute;\n  bottom: 0;\n  left: 0;\n}\n.pagelet-objective-overview-person .rightImg[data-v-86719e4e] {\n  position: absolute;\n  top: 50px;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
